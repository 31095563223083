import router from '@/router'
export default {
  state: {
    measurement_unit: {},
    measurement_units: {
      data: [],
      paginator: {
        total_pages: 1,
        current_pages: 1,
        total_items: 1
      }
    }
  },

  mutations: {
    setMeasurement_unit (state, payload) {
      state.measurement_unit = payload
    },
    setMeasurement_units (state, payload) {
      state.measurement_units = payload
    }
  },

  actions: {
    getMeasurement_units ({commit, state}) {
      commit('setLoading', true)
      this._vm.$http
      .get('measurement_units?page=' + state.measurement_units.paginator.current_pages)
      .then(response => {
        commit('setMeasurement_units', response.data)
        commit('setLoading', false)
      })
      .catch(error => {
        commit('setLoading', false)
        if (error.response.status === 401) {
          // REFRESH
          router.push('/getpass')
          commit('setError', error.response.data.message)
        }
        commit('setError', error.response.data.message)
      })
    },
    getMeasurement_unit ({commit}, id) {
      commit('setLoading', true)
      this._vm.$http
      .get('measurement_unit?id=' + id)
      .then(response => {
        commit('setMeasurement_unit', response.data)
        commit('setLoading', false)
      })
      .catch(error => {
        commit('setLoading', false)
        if (error.response.status === 401) {
          // REFRESH
          router.push('/getpass')
          commit('setError', error.response.data.message)
        }
        commit('setError', error.response.data.message)
      })
    },
    createMeasurement_unit ({commit, dispatch, state}) {
      commit('setLoading', true)
      this._vm.$http
      .post('measurement_unit', state.measurement_unit)
      .then(() => {
        dispatch('getMeasurement_units')
        commit('setLoading', false)
        commit('setMeasurement_unit', {})
        commit('setMessage', 'Тип сада успешно создан!')
      })
      .catch(error => {
        commit('setLoading', false)
        if (error.response.status === 401) {
          // REFRESH
          router.push('/getpass')
          commit('setError', error.response.data.message)
        }
        commit('setError', error.response.data.message)
      })
    },
    updateMeasurement_unit ({commit, dispatch, state}) {
      commit('setLoading', true)
      this._vm.$http
      .put('measurement_unit', state.measurement_unit)
      .then(() => {
        dispatch('getMeasurement_units')
        commit('setLoading', false)
        commit('setMeasurement_unit', {})
        commit('setMessage', 'Тип сада успешно обновлен!')
      })
      .catch(error => {
        commit('setLoading', false)
        if (error.response.status === 401) {
          // REFRESH
          router.push('/getpass')
          commit('setError', error.response.data.message)
        }
        commit('setError', error.response.data.message)
      })
    },
    deleteMeasurement_units ({commit, dispatch}, item) {
      commit('setLoading', true)
      this._vm.$http
      .delete('measurement_unit?id=' + item.id)
      .then(() => {
        dispatch('getMeasurement_units')
        commit('setLoading', false)
        commit('setMeasurement_unit', {})
        commit('setMessage', 'Тип сада успешно удален!')
      })
      .catch(error => {
        commit('setLoading', false)
        if (error.response.status === 401) {
          // REFRESH
          router.push('/getpass')
          commit('setError', error.response.data.message)
        }
        commit('setError', error.response.data.message)
      })
    }
  },

  getters: {
    measurement_unit (state) {
      return state.measurement_unit
    },
    measurement_units (state) {
      return state.measurement_units
    }
  }
}
