<template>
  <div>
    <div @click="$router.back()" class="back-btn">
      <v-icon class="ml-0">chevron_left</v-icon>назад
    </div>
    <h1 class="display-1" v-if="warehouse.id">Редактирование склада</h1>
    <h1 class="display-1" v-else><h1 class="display-1">Новый склад</h1></h1>
    <v-divider class="mt-2 mb-8"></v-divider>

    <v-row class="form-grid">
      <v-col cols="7">
        <v-text-field label="Наименование" outlined v-model="warehouse.name"></v-text-field>
        <v-select
          :items="warehouses_types"
          v-model="warehouse.warehouses_type_id"
          outlined
          label="Тип склада"
          item-text="name"
          item-value="id"
        ></v-select>
        <v-text-field label="Объем хранения" outlined v-model.number="warehouse.volume"></v-text-field>
        <!-- <v-text-field label="Работник" outlined v-model="warehouse.employee_id"></v-text-field> -->
        <v-textarea label="Описание" outlined v-model="warehouse.description"></v-textarea>
      </v-col>
      <v-col cols="5">
        <v-card outlined class="files-card">
          <v-toolbar elevation="0">
            <v-toolbar-title>Документы</v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text v-if="!files">
            Что бы загрузить документы перетащите их на зону ниже или просто кликните по ней
          </v-card-text>
          <v-list
            subheader
            two-line
          >
            <div
              v-for="(file, index) in files"
              :key="index"
            >
              <v-list-item>
                <v-list-item-avatar>
                  <img :src="file.url" alt="" v-if="isImage(file.url)" @click="download(file.url)" class="file-image-preview">
                  <v-icon
                    v-else
                    class="grey lighten-1 file-doc-preview"
                    dark
                    @click="download(file.url)"
                  >mdi-file</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="file.filename" @click="download(file.url)" class="file-name"></v-list-item-title>
                  <v-list-item-subtitle v-text="'Размер: ' + file.file_size + 'кб'"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon @click="deleteFile(file.filename)">
                    <v-icon color="grey lighten-1">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index < files.length - 1"
                :key="index"
              ></v-divider>

            </div>
          </v-list>

          <vue-dropzone
            ref="myVueDropzone"
            id="dropzone"
            v-on:vdropzone-sending="sendingFileEvent"
            v-on:vdropzone-complete="completeFileEvent"
            class="dropzone"
            :options="dropzoneOptions"
          ></vue-dropzone>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="warehouse.id">
      <v-btn x-large depressed color="light-grey" @click="updateWarehouse" class="mr-3">Обновить</v-btn>
      <v-btn x-large depressed color="light-red" @click="deleteWarehouse" class="">Удалить склад</v-btn>
    </div>
    <div v-else>
      <v-btn x-large depressed color="light-grey" @click="createWarehouse" class="mr-3">Создать</v-btn>
    </div>

  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
  name: 'Warehouse',
  props: ['id', 'parent_id'],
  components: {
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      dropzoneOptions: {
          url: `${process.env.VUE_APP_ROOT_API}file_upload`,
          thumbnailWidth: 150,
          thumbnailHeight: 150,
          maxFilesize: 50,
          method: 'PUT',
          dictDefaultMessage: "<i class='v-icon notranslate mdi mdi-cloud-upload theme--light'></i> ЗАГРУЗИТЬ ФАЙЛЫ",
          headers: { 'Authorization': 'Bearer ' + localStorage.jwt }
      }
    }
  },
  methods: {
    sendingFileEvent (file, xhr, formData) {
      formData.append('model', 'warehouse');
      formData.append('id', this.id);
    },
    completeFileEvent (info) {
      let response = JSON.parse(info.xhr.response)
      this.$store.commit('setMessage', response.human_data)
      this.$store.dispatch('getFiles', { model: 'warehouse', id: this.id })
    },
    download (url) {
      window.location.href = url;
    },
    deleteFile (name) {
      let payload = {
        product_id: this.id,
        file_name: name,
        model: 'warehouse'
      }
      confirm('Вы уверены что хотите файл? Вернуть его уже будет нельзя!') && this.$store.dispatch('deleteFile', payload)
    },
    isImage(file) {
      return file.match(/\.(jpg|jpeg|png|gif)$/)
    },
    updateWarehouse () {
      this.$store.dispatch('updateWarehouse')
    },
    createWarehouse () {
      this.warehouse.parent_id = parseInt(this.parent_id)
      this.$store.dispatch('createWarehouse')
    },
    deleteWarehouse () {
      confirm('Вы уверены что хотите удалить склад? Вернуть его уже будет нельзя!') && this.$store.dispatch('deleteWarehouse', this.warehouse)
    },
  },
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    warehouse() {
      return this.$store.getters.warehouse
    },
    warehouses_types() {
      return this.$store.getters.warehouses_types.data
    },
    files () {
      return this.$store.getters.files
    },
  },
  created() {
    this.$store.dispatch('getWarehouses_types')
    if (!isNaN(this.id)) {
      this.$store.dispatch('getWarehouse', this.id)
      this.$store.dispatch('getFiles', { model: 'warehouse', id: this.id })
    }
  },
  beforeDestroy () {
    this.$store.commit('setWarehouse', {})
  }
}
</script>

<style lang="scss">
  .form-grid {
    margin-bottom: 0px;
    .row {
      margin-top: 0;
    }
    .col {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .dropzone {
    min-height: 300px;
  }
</style>
